// extracted by mini-css-extract-plugin
export var btnDefault = "main-title-card-module--btn-default--A324t";
export var attoiconJobs = "main-title-card-module--attoicon-jobs--8853z";
export var attoiconNotes = "main-title-card-module--attoicon-notes--XeMxl";
export var attoiconTimesheets = "main-title-card-module--attoicon-timesheets--C-Mi9";
export var attoiconTimesheetsRounded1 = "main-title-card-module--attoicon-timesheets-rounded1--MqLeF";
export var attoiconMobileTt = "main-title-card-module--attoicon-mobile-tt--qqmWE";
export var attoiconNamedSites = "main-title-card-module--attoicon-named-sites--D-t3I";
export var attoiconProfitableBids = "main-title-card-module--attoicon-profitable-bids--tpPO+";
export var attoiconDepartments = "main-title-card-module--attoicon-departments--1S1DI";
export var attoiconTimeline = "main-title-card-module--attoicon-timeline--KM0YJ";
export var attoiconFilters = "main-title-card-module--attoicon-filters--P9l70";
export var attoiconVerified = "main-title-card-module--attoicon-verified--XlGvn";
export var attoiconTimezone = "main-title-card-module--attoicon-timezone--5-7Vj";
export var attoiconManualEntries = "main-title-card-module--attoicon-manual-entries--nbKTk";
export var attoiconJobCosting = "main-title-card-module--attoicon-job-costing--CFAHI";
export var attoiconLocationTracking = "main-title-card-module--attoicon-location-tracking--06n01";
export var attoiconBreak = "main-title-card-module--attoicon-break--CrzJv";
export var attoiconNightShift = "main-title-card-module--attoicon-night-shift--BFHeZ";
export var attoiconOvertime = "main-title-card-module--attoicon-overtime--Z-txD";
export var attoiconTimeTracking1 = "main-title-card-module--attoicon-time-tracking1--3DzOo";
export var attoiconTimeTracking = "main-title-card-module--attoicon-time-tracking--H+dbe";
export var attoiconLocationHistory = "main-title-card-module--attoicon-location-history--4cnwl";
export var attoiconPrivacy = "main-title-card-module--attoicon-privacy--HMydQ";
export var attoiconTimeEntryHistory = "main-title-card-module--attoicon-time-entry-history--Al8mZ";
export var attoiconCustomize = "main-title-card-module--attoicon-customize--1om-d";
export var attoiconRoundedClock = "main-title-card-module--attoicon-rounded-clock--kgf1w";
export var attoiconTimeOff = "main-title-card-module--attoicon-time-off--t9ml9";
export var attoiconNamedLocations = "main-title-card-module--attoicon-named-locations--piD9i";
export var attoiconSandClock = "main-title-card-module--attoicon-sand-clock--3o6Z9";
export var attoiconBattery = "main-title-card-module--attoicon-battery--tqTVR";
export var attoiconSupport = "main-title-card-module--attoicon-support--+PaB7";
export var attoiconHelpSupport = "main-title-card-module--attoicon-help-support--4IY+m";
export var attoiconWebTt = "main-title-card-module--attoicon-web-tt--trR-N";
export var attoiconArchive = "main-title-card-module--attoicon-archive--RiGQ3";
export var attoiconEmail = "main-title-card-module--attoicon-email--MK29w";
export var attoiconKiosk = "main-title-card-module--attoicon-kiosk--BgW2s";
export var attoiconShare = "main-title-card-module--attoicon-share--8TlYg";
export var attoiconCrew = "main-title-card-module--attoicon-crew--KvMQl";
export var attoiconTeamActivity = "main-title-card-module--attoicon-team-activity--4m+Da";
export var attoiconTeam = "main-title-card-module--attoicon-team--b093b";
export var attoiconWages = "main-title-card-module--attoicon-wages--FEegN";
export var attoiconNotification = "main-title-card-module--attoicon-notification--Zx5Mo";
export var attoiconAvatar = "main-title-card-module--attoicon-avatar--19OoN";
export var attoiconViewMap = "main-title-card-module--attoicon-view-map--v-mQ1";
export var attoiconMovementTracking = "main-title-card-module--attoicon-movement-tracking--QhDlw";
export var attoiconWageEstimates = "main-title-card-module--attoicon-wage-estimates--3hCgX";
export var attoiconWageEstimatesBold = "main-title-card-module--attoicon-wage-estimates-bold--gRWw8";
export var attoiconClose = "main-title-card-module--attoicon-close--824sb";
export var attoiconPlus = "main-title-card-module--attoicon-plus--mHzFQ";
export var attoiconMinus = "main-title-card-module--attoicon-minus--ObgDR";
export var attoiconTick = "main-title-card-module--attoicon-tick--gNiBk";
export var attoiconArrowLeft = "main-title-card-module--attoicon-arrow-left--j3oUt";
export var attoiconArrowRight = "main-title-card-module--attoicon-arrow-right--Fvrbi";
export var attoiconArrowDown = "main-title-card-module--attoicon-arrow-down--+9ITx";
export var attoiconArrowUp = "main-title-card-module--attoicon-arrow-up--qbhOm";
export var attoiconPlay = "main-title-card-module--attoicon-play--38Duz";
export var attoiconLongArrow = "main-title-card-module--attoicon-long-arrow--z6sQQ";
export var container = "main-title-card-module--container--n063K";
export var headerContainer = "main-title-card-module--headerContainer--waudp";
export var smallParagraph = "main-title-card-module--smallParagraph--6Xum5";
export var mainTitle = "main-title-card-module--mainTitle--WBDZ8";
export var paragraphStyle = "main-title-card-module--paragraphStyle--t9++u";
export var imageParagraphStyle = "main-title-card-module--imageParagraphStyle--fGX5t";
export var imageWrapper = "main-title-card-module--imageWrapper--qKDVv";